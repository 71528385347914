import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Image from "./image";
import PropTypes from "prop-types";
import PageLink from "./PageLink";

const FeaturedItemContainerLink = styled(PageLink)`
  margin-bottom: 3.5rem;
  display: block;
`;

const FeaturedContent = styled.div`
  background-color: ${props => props.backgroundColor};
  width: 100%;
  padding: 3.5rem 2.5rem 4.5rem;
  position: relative;
  overflow: hidden;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 9rem 0;
  }
`;

const FeaturedBackgroundDesktop = styled(Image)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    z-index: 1;
    display: block;
    transition: all 1s;
    transform-origin: center right;
    &:hover {
      transform: scale(1.05);
    }
  }
`;

const FeaturedBackgroundTablet = styled(Image)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    z-index: 1;
    display: block;
    transition: all 1s;
    transform-origin: center right;
    &:hover {
      transform: scale(1.05);
    }
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

const FeaturedBackgroundImageMobile = styled(Image)`
  width: 100%;
  height: auto;
  min-height: 260px;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`;

const FeaturedBox = styled.div`
  position: relative;
  z-index: 2;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 44.609375%;
    margin-left: 6.484375%;
    margin-right: 47.8125%;
  }
  /* margin: 3.5rem 2.5rem; */
`;

const FeaturedLogo = styled(Image)`
  max-width: 179px;
  width: 100%;
  height: auto;
  margin-bottom: 3.5rem;
  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 300px;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 7rem;
    max-width: 466px;
    width: 100%;
    height: auto;
  }
`;

const FeaturedSubtitle = styled.h3`
  font-family: ${props => props.theme.fonts.regular};
  font-size: 3.1rem;
  line-height: 38px;
  color: #f4edeb;
  margin-bottom: 4.5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 4.8rem;
    line-height: 62px;
    margin-bottom: 5.5rem;
  }
`;

const FeaturedExercpt = styled.p`
  display: none;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: block;
    font-family: ${props => props.theme.fonts.regular};
    font-size: 2.5rem;
    line-height: 39px;
    width: 83.362521891%;
    color: ${props => props.theme.colors.lightPeach};
    margin-bottom: 5rem;
  }
`;

const FeaturedLink = styled.span`
  font-family: ${props => props.theme.fonts.regular};
  font-size: 1.8rem;
  line-height: 25px;
  letter-spacing: 2.7px;
  text-transform: uppercase;
  text-decoration: underline;
  color: #f4edeb;
`;

const CaseStudyCard = ({
  caseStudy: {
    // databaseId,
    // title,
    uri,
    CaseStudy: {
      card: {
        logo,
        title: cardTitle,
        description,
        ctaText,
        backgroundImageDesktop,
        backgroundImageTablet,
        backgroundImageMobile,
        backgroundColor,
      },
    },
  },
}) => {
  // console.log(backgroundImage.localFile?.childImageSharp.fluid);
  return (
    <FeaturedItemContainerLink to={uri}>
      <FeaturedContent backgroundColor={backgroundColor}>
        <FeaturedBox>
          {logo && <FeaturedLogo image={logo} />}
          <FeaturedSubtitle>{cardTitle}</FeaturedSubtitle>
          <FeaturedExercpt>{description}</FeaturedExercpt>
          <FeaturedLink>{ctaText}</FeaturedLink>
        </FeaturedBox>
        <FeaturedBackgroundDesktop
          imgStyle={{ objectPosition: "right top" }}
          image={backgroundImageDesktop}
          css={css`
            height: 100%;
          `}
        />
        <FeaturedBackgroundTablet
          imgStyle={{ objectPosition: "right top" }}
          image={backgroundImageTablet}
          css={css`
            height: 100%;
          `}
        />
      </FeaturedContent>
      <FeaturedBackgroundImageMobile
        image={backgroundImageMobile}
        imgStyle={{
          objectPosition: "center right",
        }}
      />
    </FeaturedItemContainerLink>
  );
};

export default CaseStudyCard;

CaseStudyCard.propTypes = {
  caseStudy: PropTypes.shape({
    databaseId: PropTypes.number,
    title: PropTypes.string,
    uri: PropTypes.string,
    CaseStudy: PropTypes.shape({
      fieldGroupName: PropTypes.string,
      card: PropTypes.shape({
        logo: PropTypes.object,
        title: PropTypes.string,
        description: PropTypes.string,
        ctaText: PropTypes.string,
        backgroundImage: PropTypes.object,
      }),
    }),
  }),
};
